// vendors
import { useState } from "react";
import {
    Button,
    Drawer, Grid, Select,
} from "@mantine/core"
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";
import CustomersSelectField from '../../../../../components/CustomersSelectField';


export default function FilterDrawer(props: {
    opened: boolean,
    onClose: () => void,
    onApply?: (filter: { [key: string]: string }) => void,
}) {

    const { t } = useTranslation();
    const [filter, setFilter] = useState<{ key: string, value: string }[]>([]);

    const addFilter = (key: string, value: any) => {
        setFilter([...filter.filter(f => f.key !== key), { key, value }]);
    }

    const removeFilter = (key: string) => {
        setFilter(filter.filter(f => f.key !== key));
    }

    return (
        <Drawer
            position="left"
            padding="xl"
            size={300}
            withOverlay={false}
            {...props}
            onClose={() => {
                props.onClose();
            }}
            title={t("Filter")}
        >
            <Grid>
                <Grid.Col span={12}>
                    <Select
                        size="xs"
                        label={t("نوع الفاتورة")}
                        data={[
                            { label: t("All") as string, value: "all" },
                            { label: t("فاتورة مبيعات") as string, value: "sales" },
                            { label: t("فاتورة ايداع") as string, value: "deposit" },
                            // { label: t("فاتورة استثمار"), value: "investment" },

                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("invoice_type");
                            else addFilter("invoice_type", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <CustomersSelectField
                        // disable={isEdit ? true : false}
                        onlyInvestors={null}
                        label={t("الزبون")}
                        placeholder={t("الزبون")}
                        onSelect={(customer) => {
                            // if (customer) form.setFieldValue('from_to', customer?.firstname + ' ' + customer?.lastname);
                            addFilter('customer_id', customer.id);
                        }}
                    // {...form.getInputProps('customer')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        size="xs"
                        label={t("إتجاه الفاتورة")}
                        data={[
                            { label: t("All") as string, value: "all" },
                            { value: 'in', label: t("إيداع") as string },
                            { value: 'out', label: t("سحب") as string },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("direction");
                            else addFilter("direction", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        size="xs"
                        label={t("نوع الاصل")}
                        data={[
                            { label: t("All") as string, value: "all" },
                            { value: 'cash', label: t("نقد") as string },
                            { value: 'bank', label: t("بنك") as string },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("asset_type");
                            else addFilter("asset_type", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <DatePicker
                        locale="ar"
                        size="xs"
                        label={t("اختر التاريخ")}
                        defaultValue={new Date()}
                        onChange={(value) => {
                            if (value) {
                                const formattedDate = dayjs(value).format("YYYY-MM-DD");
                                console.log(formattedDate);
                                addFilter("issued_at", formattedDate);
                            } else {
                                removeFilter("issued_at");
                            }
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Button
                        size="xs"
                        onClick={() => {
                            props.onApply && props.onApply(
                                filter.reduce((acc: { [key: string]: string }, f) => {
                                    acc[f.key] = f.value;
                                    return acc;
                                }, {})
                            );
                        }}
                    >
                        {t("Apply")}
                    </Button>
                </Grid.Col>
            </Grid>
        </Drawer>
    )

}
