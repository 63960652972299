import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Loader, Modal, ScrollArea, Switch } from '@mantine/core';
import { IconAlertCircle, IconCloudUpload } from '@tabler/icons';

// utils 
import Dropzone from './Dropzone';
import { DataGridPaginationState, OnChangeCallback } from 'mantine-data-grid';
import { showNotification } from '@mantine/notifications';
import { getMyDocuments } from '../../api/documents';
import ImageBox from './ImageBox';
import NoDataFound from '../NoDataFound';

// types
import { Document as DocumentType } from '../../types/response';

export default function MediaLibrary(
    {
        opened,
        onClose,
        multiple = false,
        filename = "",
        onSelect = (data: DocumentType[]) => { },
        onDeselect = (data: DocumentType) => { },
        defaultSelected = [],
    }: {
        opened: boolean,
        onClose: () => void,
        multiple?: boolean | undefined,
        filename?: string,
        onSelect?: (data: DocumentType[]) => void,
        onDeselect?: (data: DocumentType) => void,
        defaultSelected?: DocumentType[],
    }
) {

    const [loading, setLoading] = useState<boolean>(true);
    const [noData, setNoData] = useState<boolean>(false);
    const [data, setData] = useState<DocumentType[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [dropzoneState, setDropzoneState] = useState<boolean>(false);
    const [privateFile, setPrivateFile] = useState<boolean>(false);
    // const [editData, setEditData] = useState<null | DocumentType>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    // const [addDataDrawerState, setAddDataDrawerState] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const [selected, setSelected] = useState<DocumentType[]>(defaultSelected);

    const scrollRef = useRef<HTMLDivElement>(null)

    const selectedBeforeOpen = useMemo(() => defaultSelected, [opened]);


    const loadData = async (page: number, limit: number, search: string = '') => {
        setLoading(true);
        getMyDocuments(page, limit).then(res => {
            setData([
                ...data,
                ...res.data.documents,
            ]);
            setTotal(res.data.total);
            if (res.data.documents.length < limit) {
                setHasMore(false);
            }
        }).catch(err => {
            if (err.response.status === 404) {
                if (data.length != 0) {
                    setHasMore(false);
                    return;
                }
                setNoData(true);
                return;
            }
            console.error(err);
            const error = {
                title: 'Error',
                message: 'Error while fetching data',
                color: 'red',
                icon: <IconAlertCircle />,
            }
            showNotification(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const load: OnChangeCallback<DataGridPaginationState> = async ({ pageIndex, pageSize }) => {
        setCurrentPage(pageIndex);
        await loadData(pageIndex + 1, pageSize, searchValue);
    };

    const search: OnChangeCallback<string> = async (value) => {
        setSearchValue(value);
        await loadData(1, 9, value);
    };

    useEffect(() => {
        load({ pageIndex: 0, pageSize: 9 });
        // loadData(1, 1);
    }, []);

    const refreshData = () => {
        setData([]);
        setTotal(0);
        setCurrentPage(0);
        setHasMore(true);
        load({ pageIndex: 0, pageSize: 9 });
    };



    return (
        <Modal
            opened={opened}
            onClose={() => onClose()}
            title="مكتبة الوسائط"
            size={'xl'}
            centered
        >
            <Box ref={scrollRef}>
                <ScrollArea
                    sx={{ height: '70vh' }}
                    onScrollPositionChange={(position) => {
                        const elContainer = scrollRef.current?.closest('div')
                        let elViewport = scrollRef.current?.querySelector('.mantine-rtl-ScrollArea-viewport > div')
                        if (!elViewport) {
                            elViewport = scrollRef.current?.querySelector('.mantine-ScrollArea-viewport > div')
                        }
                        if (elContainer && elViewport) {

                            const pos = position.y / (elViewport?.scrollHeight - elContainer?.scrollHeight)

                            if (pos >= .98) {
                                if (!loading && hasMore) {
                                    // if (hasMore) {
                                    // setCurrentPage(currentPage + 1);
                                    // setLoading(true);
                                    load({ pageIndex: currentPage + 1, pageSize: 9 });
                                }
                            }
                        }
                    }}
                >
                    <Button
                        leftIcon={<IconCloudUpload size={16} />}
                        onClick={() => setDropzoneState(!dropzoneState)}
                        ml={'md'}
                        mb={'md'}
                    >
                        رفع
                    </Button>
                    {
                        Boolean(dropzoneState) && (
                            <>
                                <Switch
                                    label="ملف خاص"
                                    mx={'md'}
                                    my={'md'}
                                    onChange={(e) => setPrivateFile(e.currentTarget.checked)}
                                    checked={privateFile}
                                />
                                <Dropzone
                                    mx={'md'}
                                    filename={filename}
                                    privateFile={privateFile}
                                    onUpload={(document: DocumentType) => {
                                        setNoData(false);
                                        setData([
                                            document,
                                            ...data,
                                        ])
                                    }}
                                />
                            </>
                        )
                    }
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            loading && data.length === 0 ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} my={'xl'}>
                                    <Loader />
                                </Box>
                            ) : noData ? (
                                <Box mx={'md'} my={'md'} sx={{ width: '100%' }}>
                                    <NoDataFound />
                                </Box>
                            ) : (
                                <>
                                    {
                                        selectedBeforeOpen.map((item: DocumentType, index) => (
                                            <ImageBox
                                                key={index}
                                                data={item}
                                                selected={selected.filter((i) => i.id === item.id).length > 0}
                                                onClick={() => {
                                                    if (multiple) {
                                                        if (selected.filter((i) => i.id === item.id).length > 0) {
                                                            setSelected(selected.filter((i) => i.id !== item.id))
                                                            onDeselect(item);
                                                        } else {
                                                            setSelected([...selected, item])
                                                            onSelect([...selected, item]);
                                                        }
                                                    } else {
                                                        setSelected([])
                                                        onSelect([item])
                                                        onClose()
                                                    }
                                                }}
                                                onDelete={() => {
                                                    refreshData();
                                                }}
                                            />
                                        ))
                                    }
                                    {
                                        data.map((item: DocumentType, index) => (
                                            selectedBeforeOpen.findIndex(doc => doc.id === item.id) === -1 && <ImageBox
                                                key={index}
                                                data={item}
                                                selected={selected.filter((i) => i.id === item.id).length > 0}
                                                onClick={() => {
                                                    // if ( defaultSelected.filter((i) => i.id === item.id).length > 0 ) {
                                                    //     onDeselect(item);
                                                    //     return;
                                                    // }
                                                    if (multiple) {
                                                        if (selected.filter((i) => i.id === item.id).length > 0) {
                                                            setSelected(selected.filter((i) => i.id !== item.id))
                                                            onDeselect(item);
                                                        } else {
                                                            setSelected([...selected, item])
                                                            onSelect([...selected, item]);
                                                        }
                                                    } else {
                                                        setSelected([])
                                                        onSelect([item])
                                                        onClose()
                                                    }
                                                }}
                                                onDelete={() => {
                                                    refreshData();
                                                }}
                                            />
                                        ))
                                    }
                                    {
                                        hasMore && data.length && (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} my={'xl'}>
                                                <Loader />
                                            </Box>
                                        )
                                    }
                                </>
                            )
                        }
                    </Box>
                </ScrollArea>
                {
                    multiple && (
                        <Box pt={'sm'} pl={'sm'}>
                            <Button
                                onClick={() => {
                                    // setSelected([])
                                    onSelect(selected)
                                    onClose()
                                }}
                            >
                                تحديد
                            </Button>
                        </Box>
                    )
                }
            </Box>
        </Modal>
    );
}