// vendors
import {
    Box,
    Button,
} from "@mantine/core";
import dayjs from 'dayjs';
import {
    dateFilterFn,
    highlightFilterValue,

    stringFilterFn,
} from 'mantine-data-grid';

// utils
import PageHeading from "../../../../components/PageHeading";
import AddData from "./_AddDrawer";
import EditData from "./_EditDrawer";
import SkeletonRows from "../../../../components/SkeletonRows";
import { useTranslation } from 'react-i18next';
import { useHasPermission } from '../../../../hooks';

// api
import { getContracts } from "../../../../api/contract";

// types
import { Contract as ContractType } from "../../../../types/response";
import NoDataFound from '../../../../components/NoDataFound';
import NoPermisionPage from '../../../../components/NoPermisionPage';
import { useData } from '../../../../hooks/useUsersData';
import DataGridComponent from '../../../../components/DataGridComponent';
export default function Users() {
    const { t } = useTranslation();

    const permissionGranted = useHasPermission(["contracts.read"]);
    const {
        loading,
        data,
        noData,
        total,
        editData,
        setEditData,
        addDataDrawerState,
        setAddDataDrawerState,
        load,
        search,
        refreshData,
    } = useData<ContractType>({
        fetchData: (page, limit, search) => getContracts(page, limit, search).then(res => ({
            data: res.data.contracts,
            total: res.data.total,
        })),
        errorMessage: 'Error while fetching user data',
    });

    if (!permissionGranted) {
        return <NoPermisionPage />;
    }
    const columns = [
        {
            accessorKey: 'subject',
            header: t('Subject') as string,
            filterFn: stringFilterFn,
            size: 300,
            cell: highlightFilterValue,
        },
        {
            header: t('Price') as string,
            accessorKey: 'price',
            filterFn: stringFilterFn,
            cell: (props: any): string => props?.row?.original?.price ? `${props.row.original.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
        },
        {
            header: t('Customer') as string,
            accessorKey: 'customer',
            filterFn: stringFilterFn,
            cell: (data: any): string => (
                // i18n.language === 'ar' ? (
                //     data.row.original.customer.firstname_ar ? (
                //         `${data.row.original.customer.firstname_ar} ${data.row.original.customer.lastname_ar}`
                //     ) : (
                //         `${data.row.original.customer.firstname} ${data.row.original.customer.lastname}`
                //     )
                // ) : (
                // data.row.original.customer.firstname ? (
                `${data.row.original.customer.full_name} `
                // ) : (
                //     `${data.row.original.customer.firstname_ar} ${data.row.original.customer.lastname_ar}`
                // )
                // )
            ),
        },
        {
            header: t('Property') as string,
            accessorKey: 'property',
            filterFn: stringFilterFn,
            cell: (data: any): string => `${data.row.original.property.name}`,
        },
        {
            header: t('Date') as string,
            accessorKey: 'created_at',
            filterFn: dateFilterFn,
            cell: (props: any): string => dayjs(props.row.original.created_at).format('DD/MM/YYYY'),
        },
    ];
    return (
        <>
            <PageHeading>
                {t("Contracts")}
            </PageHeading>
            <Button
                mx={20}
                mb={20}
                onClick={() => setAddDataDrawerState(true)}
            >
                {
                    t("Add")
                }
            </Button>
            {/* <TextInput
                px={20}
                pb={20}
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
            /> */}
            <Box
                sx={{
                    padding: '0 20px',
                }}
            >
                {
                    data ? (
                        <DataGridComponent
                            data={data}
                            total={total}
                            loading={loading}
                            onPageChange={load}
                            onSearch={search}
                            onRowClick={(row) => setEditData(row)}
                            columns={columns}

                        />
                    ) : noData ? (
                        <NoDataFound />
                    ) : (
                        <SkeletonRows count={10} height={20} />
                    )
                }
            </Box>

            <AddData
                opened={addDataDrawerState}
                refreshData={refreshData}
                onClose={() => setAddDataDrawerState(false)}
            />

            <EditData
                data={editData}
                opened={editData !== null}
                refreshData={refreshData}
                onClose={() => setEditData(null)}
            />
        </>
    );
}