import i18next from "i18next";

/**
 * @param {any} value
 * @param {string} replacement
 * @returns {any}
 * @description replace the invalid values such as null, false, with the default value
 */
export function validateValue(value: any, replacement: string = '-'): any {
    if (value === null || value === '' || value === false)
        return replacement;
    return value;
}


/**
 * @param {number} length
 * @returns {string}
 * @description generate a random string
 */
export function randomString(length: number = 10): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$!-_&()';
    for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
}


/**
 * @param {number[]} arr
 * @returns {google.maps.LatLngLiteral[]}
 * @description convert an array of numbers to an array of LatLngLiteral
 */
export function geoToLatLng(arr: number[]): google.maps.LatLng[] {
    let res: google.maps.LatLng[] = [];
    for (let i = 0; i < arr.length; i += 2)
        res.push(new google.maps.LatLng(arr[i], arr[i + 1]));
    return res;
}


/**
 * @param {google.maps.LatLng[]} arr
 * @returns {number[]}
 * @description convert an array of LatLngLiteral to an array of numbers
 */
export function latLngToGeo(arr: google.maps.LatLng[]): number[] {
    let res: number[] = [];
    for (let i = 0; i < arr.length; i++)
        res.push(arr[i].lat(), arr[i].lng());
    return res;
}


export function latLngAverage(arr: google.maps.LatLng[]): google.maps.LatLng {
    let lat = 0;
    let lng = 0;
    for (let i = 0; i < arr.length; i++) {
        lat += arr[i].lat();
        lng += arr[i].lng();
    }
    return new google.maps.LatLng(lat / arr.length, lng / arr.length);
}


export function parseError(error: any): string {
    if (error.response?.data?.msg || error.response?.data?.msg_ar) {
        return i18next.language === 'en' || !error.response.data.msg_ar ? error.response.data.msg : error.response.data.msg_ar;
    }

    if (error.response?.data?.message) {
        return i18next.language === 'en' || !error.response.data.message_ar ? error.response.data.message : error.response.data.message_ar;
    }

    if (error.response?.data?.error) return error.response.data.error;

    if (error.message) return error.message;

    return "Unknown error occurred";
}


export function imageLink(path: string): string {
    return `https://system.alemtilak.com/api/${path}`;
}

export function elementIsVisibile(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
}

// export function params(obj: any) {
//     return Object.keys(obj).map(key => key + '=' + );
//     return (obj ? {obj} : {});
// }