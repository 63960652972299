// vendors
import { useState } from "react";
import {
    Button,
    Drawer, Grid, NumberInput, Select,
} from "@mantine/core"
import { useTranslation } from "react-i18next";
import { IconBath, IconBed, IconBooks, IconBrandVercel, IconEyeCheck } from "@tabler/icons";

export default function FilterDrawer(props: {
    loading: boolean,
    opened: boolean,
    onClose: () => void,
    onApply?: (filter: { [key: string]: string }) => void,
}) {

    const { t } = useTranslation();
    const [filter, setFilter] = useState<{ key: string, value: string }[]>([]);

    const addFilter = (key: string, value: any) => {
        setFilter([...filter.filter(f => f.key !== key), { key, value }]);
    }

    const removeFilter = (key: string) => {
        setFilter(filter.filter(f => f.key !== key));
    }

    const { loading, ...drawerProps } = props;
    const [roomValue, setRoomValue] = useState<number | undefined>(undefined);
    const [bathValue, setBathValue] = useState<number | undefined>(undefined);
    const clampValue = (val: number | undefined) => {
        if (val === undefined) return undefined;
        if (val < 0) return 0;
        return val;
    };
    return (
        <Drawer
            position="left"
            padding="xl"
            size={300}
            withOverlay={false}
            {...drawerProps}
            onClose={() => {
                drawerProps.onClose();
            }}
            title={t("Filter")}
        >
            <Grid>
                <Grid.Col span={12}>
                    <Select
                        icon={<IconBrandVercel size={16} />}
                        size="xs"
                        label={t("Status")}
                        data={[
                            { label: t("All") as string, value: "all" },
                            { value: 'listed', label: t("Listed") as string },
                            { value: 'sold', label: t("Sold") as string },
                            { value: 'reserved', label: t("Reserved") as string },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("status");
                            else addFilter("status", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        icon={<IconEyeCheck size={16} />}
                        size="xs"
                        label={t("Status")}
                        data={[
                            { label: t("All") as string, value: "all" },
                            { value: 'approved', label: t("Approved") as string },
                            { value: 'refused', label: t("Rejected") as string },
                            { value: 'pending', label: t("Pending") as string },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("publish_state");
                            else addFilter("publish_state", value);
                        }}
                    />
                </Grid.Col>

                <Grid.Col span={12}>
                    <Select
                        icon={<IconEyeCheck size={16} />}
                        size="xs"
                        label={t("Published")}
                        data={[
                            { label: t("All") as string, value: "all" },
                            { value: '1', label: t("Published") as string },
                            { value: '0', label: t("Unpublished") as string },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("public");
                            else addFilter("public", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <NumberInput
                        icon={<IconBed size={16} />}
                        size="xs"
                        label={t("Rooms")}
                        min={0}
                        max={100}
                        step={1}
                        noClampOnBlur
                        placeholder={t("All") as string}
                        value={roomValue}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const inputValue = e.target.value;

                            if (+inputValue < 0) {
                                e.target.value = "0";
                            }
                        }}
                        onChange={(val) => {
                            const clampedValue = clampValue(val);
                            setRoomValue(clampedValue);
                            if (clampedValue === undefined) {
                                removeFilter("rooms");
                            } else {
                                addFilter("rooms", clampedValue);
                            }
                        }}
                        parser={(value) => (value === null ? "" : value?.replace(/\D/g, ''))}
                        formatter={(value) => (value === undefined ? t("All") : value.toString())}
                    />

                </Grid.Col>
                <Grid.Col span={12}>
                    <NumberInput
                        icon={<IconBath size={16} />}
                        size="xs"
                        label={t("Bathrooms")}
                        min={0}
                        max={100}
                        step={1}
                        placeholder={t("All") as string}
                        value={bathValue}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const inputValue = e.target.value;
                            if (+inputValue < 0) {
                                e.target.value = "0";
                            }
                        }}
                        onChange={(val) => {
                            const clampedValue = clampValue(val);
                            setBathValue(clampedValue);
                            if (val === null) {
                                removeFilter("bathrooms");
                            } else {
                                addFilter("bathrooms", val);
                            }
                        }}
                        parser={(value) => (value === null ? "" : value?.replace(/\D/g, ''))}
                        formatter={(value) => (value === undefined ? t("All") : value.toString())}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        size="xs"
                        label={t("Furniture")}
                        icon={<IconBooks size={16} />}
                        data={[
                            { label: t("All") as string, value: "all" },
                            { label: t("Furnished") as string, value: '1' },
                            { label: t("Unfurnished") as string, value: '0' },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("furniture");
                            else addFilter("furniture", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        size="xs"
                        label={t("Property ownership")}
                        data={[
                            { label: t("All") as string, value: "all" },
                            { value: 'rent', label: t("Rent") as string },
                            { value: 'sale', label: t("Sell") as string },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("property_ownership");
                            else addFilter("property_ownership", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Button
                        loading={loading}
                        size="xs"
                        onClick={() => {
                            drawerProps.onApply && drawerProps.onApply(
                                filter.reduce((acc: { [key: string]: string }, f) => {
                                    acc[f.key] = f.value;
                                    return acc;
                                }, {})
                            );
                        }}
                    >
                        {t("Apply")}
                    </Button>
                </Grid.Col>
            </Grid>
        </Drawer>
    )

}