import React, { useEffect } from "react";
import { Select } from "@mantine/core";

// api
import { getCustomers, getInvestorsCustomers } from "../api/customer";

// types
import { Customer as CustomerType } from "../types/response";

export default function CustomersSelectField({
    defaultData = [],
    onlyInvestors = false,
    disable = false,
    onSelect = (user: CustomerType) => { },
    ...props
}: {
    defaultData?: CustomerType[];
    onlyInvestors?: boolean | null;
    disable?: boolean;
    onSelect?: (user: CustomerType) => void;
    [key: string]: any;
}) {

    const [data, setData] = React.useState<CustomerType[]>(defaultData);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [search, setSearch] = React.useState<string>('');
    const [value, setValue] = React.useState<string | null>(props.defaultValue ? props.defaultValue : null);

    useEffect(() => {
        if (defaultData.length > 0 && search === '') {
            setData(defaultData);
            return;
        }
        setLoading(true);
        if (onlyInvestors === true) {
            getInvestorsCustomers(1, 10, search, true).then((res) => {
                setData(res.data.customers);
            }).catch((err) => {

            }).finally(() => {
                setLoading(false);
            });
        } else if (onlyInvestors === false) {
            getInvestorsCustomers(1, 10, search, false).then((res) => {
                setData(res.data.customers);
            }).catch((err) => {

            }).finally(() => {
                setLoading(false);
            });
        } else {
            getCustomers(1, 10, search).then((res) => {
                setData(res.data.customers);
            }).catch((err) => {

            }).finally(() => {
                setLoading(false);
            });
        }
    }, [search]);

    return (
        <Select
            disabled={disable}
            label="Customer"
            placeholder={loading ? 'Loading...' : 'Select customer'}
            {...props}
            searchable
            data={[
                ...data?.map((customer: CustomerType) => ({
                    label: `${customer.full_name}    -   (${customer.email})`,
                    value: customer.id,
                }))
            ]}
            onSearchChange={(value) => {

                // setSearch(value);
            }}
            value={value}
            onChange={(v) => {
                props.onChange && props.onChange(v);
                onSelect && onSelect(data.find((user) => user.id === v) as CustomerType);
                setValue(v);
            }}
        />
    )

}