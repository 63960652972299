// vendors
import {
    Box,
    Button,
} from "@mantine/core";
import dayjs from 'dayjs';
import {
    dateFilterFn,
    highlightFilterValue,
    stringFilterFn,
} from 'mantine-data-grid';

// utils
import PageHeading from "../../../../components/PageHeading";
import AddData from "./_AddDrawer";
import EditData from "./_EditDrawer";
import SkeletonRows from "../../../../components/SkeletonRows";
import { useHasPermission } from '../../../../hooks';
import NoPermisionPage from '../../../../components/NoPermisionPage';

// api
import { getVendors } from "../../../../api/vendor";

// types
import { Contract as ContractType } from "../../../../types/response";
import NoDataFound from '../../../../components/NoDataFound';
import { useTranslation } from 'react-i18next';
import { useData } from '../../../../hooks/useUsersData';
import DataGridComponent from '../../../../components/DataGridComponent';

export default function Users() {
    const { t } = useTranslation();

    const permissionGranted = useHasPermission(["invoices.read", "properties.read"]);
    const {
        loading,
        data,
        noData,
        total,
        editData,
        setEditData,
        addDataDrawerState,
        setAddDataDrawerState,
        load,
        search,
        refreshData,
    } = useData<ContractType>({
        fetchData: (page, limit, search) => getVendors(page, limit, search).then(res => ({
            data: res.data.vendors,
            total: res.data.total,
        })),
        errorMessage: t('Error while fetching user data') as string,
    });


    const columns = [
        {
            accessorKey: 'vendorname',
            header: t('Name') as string,
            filterFn: stringFilterFn,
            size: 300,
            cell: highlightFilterValue,
        },
        {
            header: t('Address') as string,
            accessorKey: 'address',
            filterFn: stringFilterFn,
        },
        {
            header: t('Date') as string,
            accessorKey: 'created_at',
            filterFn: dateFilterFn,
            cell: (props: any): string => dayjs(props.row.original.created_at).format('DD/MM/YYYY'),
        },
    ];
    if (!permissionGranted) {
        return <NoPermisionPage />;
    }

    return (
        <>
            <PageHeading>{t('مقدمي الخدمة')}</PageHeading>
            <Button
                mx={20}
                mb={20}
                onClick={() => setAddDataDrawerState(true)}
            >
                {t('Add Item')}
            </Button>

            <Box
                sx={{
                    padding: '0 20px',
                }}
            >
                {
                    data ? (
                        <DataGridComponent
                            data={data}
                            total={total}
                            loading={loading}
                            onPageChange={load}
                            onSearch={search}
                            onRowClick={(row) => setEditData(row)}
                            columns={columns}
                        />
                    ) : noData ? (
                        <NoDataFound />
                    ) : (
                        <SkeletonRows count={10} height={20} />
                    )
                }
            </Box>

            <AddData
                opened={addDataDrawerState}
                data={data}
                refreshData={refreshData}
                onClose={() => setAddDataDrawerState(false)}
            />

            <EditData
                data={editData}
                opened={editData !== null}
                refreshData={refreshData}
                onClose={() => setEditData(null)}
            />
        </>
    );
}