// ReusableDataGrid.tsx
import React from "react";
import { Box, Button } from "@mantine/core";
import { IconBuildingBank, IconCashBanknote } from "@tabler/icons";
import { useSetState } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

// Components
import PageHeading from "../../../../../components/PageHeading";
import AddDataIn from "../Tablein/_AddDrawer";
import EditDataIn from "../Tablein/_EditDrawer";
import AddDataOut from "../Tableout/_AddDrawer";
import EditDataOut from "../Tableout/_EditDrawer";
import DataGridComponent from "../../../../../components/DataGridComponent";
import SkeletonRows from "../../../../../components/SkeletonRows";
import NoDataFound from "../../../../../components/NoDataFound";

// API and Types
import { getInvoices } from "../../../../../api/invoice";
import { useData } from "hooks/useUsersData";
import { dateFilterFn, highlightFilterValue, stringFilterFn } from "mantine-data-grid";
import dayjs from "dayjs";

interface InvoiceTableProps {
    title: string;
    invoiceType: string;
    direction: "in" | "out";
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({
    title,
    invoiceType,
    direction,
}) => {
    const { t } = useTranslation();
    const [addInvoiceDrawer, setAddInvoiceDrawer] = useSetState<{
        open?: boolean;
        type?: "bank" | "cash" | null;
    }>({ open: false, type: null });

    const closeAddDrawer = () => setAddInvoiceDrawer({ open: false, type: null });
    const openAddDrawer = (type: "bank" | "cash") =>
        setAddInvoiceDrawer({ open: true, type });

    const {
        loading,
        data,
        noData,
        total,
        editData,
        setEditData,
        load,
        search,
        refreshData,
    } = useData({
        fetchData: (page, limit, search) =>
            getInvoices(page, limit, search, {
                invoice_type: invoiceType,
                direction: direction,
            }).then((res) => ({
                data: res.data.invoices,
                total: res.data.total,
            })),
        errorMessage: t("Error while fetching data") as string,
    });
    const column = [
        {
            accessorKey: 'num_id',
            header: t('ت') as string,
            filterFn: stringFilterFn,
            // cell: highlightFilterValue,
            size: 50,
        },
        {
            accessorKey: 'subject',
            header: t('Subject') as string,
            filterFn: stringFilterFn,
            cell: highlightFilterValue,
        },

        {
            accessorKey: 'from_to',
            header: t('اسم المحاسب') as string,
            filterFn: stringFilterFn,
            size: 150,
            cell: highlightFilterValue,
        },
        {
            accessorKey: 'customer',
            header: t('الزبون') as string,
            filterFn: stringFilterFn,
            size: 150,
            cell: (props: any) => props?.row?.original?.customer?.full_name ? props?.row?.original?.customer?.full_name_ar ? props?.row?.original?.customer?.full_name_ar : props?.row?.original?.customer?.full_name : "الشركة", highlightFilterValue
        },
        {
            accessorKey: 'amount',
            header: t('المبلغ') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => props.row.original.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
        {
            header: t('Issue Date') as string,
            accessorKey: 'Issue Date',
            filterFn: dateFilterFn,
            cell: (props: any): string => dayjs(props.row.original.issued_at).format('DD/MM/YYYY'),
        },
        {
            accessorKey: 'description',
            header: t('الملاحظات') as string,
            filterFn: stringFilterFn,
            // cell: highlightFilterValue,
        },
    ];

    const AddDrawerComponent = direction === "in" ? AddDataIn : AddDataOut;
    const EditDrawerComponent = direction === "in" ? EditDataIn : EditDataOut;

    return (
        <>
            <PageHeading>{t(title)}</PageHeading>
            <Button.Group mx={20} mb={20}>
                <Button
                    variant="default"
                    onClick={() => openAddDrawer("bank")}
                    leftIcon={<IconBuildingBank size={16} />}
                >
                    {t("Add Bank Invoice")}
                </Button>
                <Button
                    variant="default"
                    onClick={() => openAddDrawer("cash")}
                    leftIcon={<IconCashBanknote size={16} />}
                >
                    {t("Add Cash Invoice")}
                </Button>
            </Button.Group>
            <Box
                sx={{
                    padding: "0 20px",
                    width: "100%",
                    height: "calc(100vh - 151.594px)",
                }}
            >
                <AddDrawerComponent
                    opened={addInvoiceDrawer.open as boolean}
                    onClose={closeAddDrawer}
                    type={addInvoiceDrawer.type as "bank" | "cash"}
                    refreshData={refreshData}
                />
                <EditDrawerComponent
                    opened={editData !== null}
                    onClose={() => setEditData(null)}
                    invoice={editData}
                    refreshData={refreshData}
                />
                {data ? (
                    <DataGridComponent
                        data={data}
                        total={total}
                        loading={loading}
                        onPageChange={load}
                        onSearch={search}
                        onRowClick={(row) => setEditData(row)}
                        columns={column}
                    />
                ) : noData ? (
                    <NoDataFound />
                ) : (
                    <SkeletonRows count={10} height={20} />
                )}
            </Box>
        </>
    );
};

export default InvoiceTable;
