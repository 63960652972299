import React, { useState } from 'react';
import {
    DataGrid,
    DataGridPaginationState,
    OnChangeCallback,
} from 'mantine-data-grid';
import { Box, Button, Dialog, Text } from '@mantine/core';
import { IconAlertCircle, IconCopy } from '@tabler/icons';
import { showNotification } from '@mantine/notifications';

interface CustomDataGridProps {
    data: any[];
    total: number;
    loading: boolean;
    onPageChange: OnChangeCallback<DataGridPaginationState>;
    onSearch?: OnChangeCallback<string>;
    onRowClick: (row: any) => void;
    columns: {
        accessorKey?: string;
        header: string;
        filterFn?: any;
        size?: number;
        cell?: <TData extends unknown>({ renderValue, column, table, }: any) => React.ReactNode;
    }[];
    getRowProps?: (row: any) => { className?: string };
}

const CustomDataGrid: React.FC<CustomDataGridProps> = ({
    data,
    total,
    loading,
    onPageChange,
    onSearch,
    onRowClick,
    columns,
    getRowProps
}) => {
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 });

    const handleRightClick = (event: React.MouseEvent, row: any) => {
        event.preventDefault();

        setSelectedRow(row);

        const offset = 20;
        const dialogWidth = 250;
        const screenWidth = window.innerWidth;
        let leftPosition = event.clientX - 10;
        let topPosition = event.clientY - offset;

        if (leftPosition + dialogWidth > screenWidth) {
            leftPosition = screenWidth - dialogWidth - 10;
        }

        setDialogPosition({
            top: topPosition,
            left: leftPosition,
        });

        setOpenDialog(true);
    };

    const handleCopy = () => {
        if (selectedRow?.id) {
            navigator.clipboard.writeText(selectedRow.id)
                .then(() => {
                    showNotification({
                        message: "تم النسخ الى الحافظة",
                        color: "green",
                        icon: <IconAlertCircle />,
                    });
                })
                .catch(() => {
                    showNotification({
                        title: "Error",
                        message: "حدث خطاً",
                        color: "red",
                        icon: <IconAlertCircle />,
                    });
                });
        }
    };

    return (
        <Box
            sx={{
                padding: '0 20px',
            }}
        >
            <style>
                {`
                @keyframes blink {
                    50% {
                        background-color: #f0e68c; /* Highlight color */
                    }
                    100% {
                        background-color: transparent;
                    }
                }
                .blinking-row {
                    animation: blink 1s linear infinite;
                }
                `}
            </style>
            <DataGrid
                data={data}
                total={total}
                onPageChange={onPageChange}
                {...({ onSearch })}
                withPagination
                verticalSpacing="md"
                paginationMode="compact"
                withSorting={true}
                withGlobalFilter={!!onSearch}
                loading={loading}
                styles={(theme) => ({
                    thead: {
                        '::after': {
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[4],
                            height: 1,
                        },
                    },
                })}
                mb={'lg'}
                onRow={({ original }) => ({
                    onClick: () => onRowClick(original),
                    onContextMenu: (event) => handleRightClick(event, original),
                    ...(getRowProps && getRowProps(original)),
                })}
                columns={columns}
            />

            <Dialog
                opened={openDialog}
                onClose={() => setOpenDialog(false)}
                withCloseButton
                position={{ top: dialogPosition.top, right: dialogPosition.left }}
                style={{ width: "250px" }}
            >
                <Text size="sm">{selectedRow?.subject ? selectedRow?.subject : selectedRow?.name}</Text>
                <Button onClick={handleCopy} leftIcon={<IconCopy />} mt="md">
                    نسخ المعرف
                </Button>
            </Dialog>
        </Box>
    );
};

export default CustomDataGrid;
