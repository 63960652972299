import { Box, Button, Switch } from "@mantine/core";
import { IconCloudUpload } from "@tabler/icons";
import { useState } from "react";

// utils
import PageHeading from "../../../../components/PageHeading";
import Dropzone from "./Dropzone";

// api
import { getMyDocuments } from "../../../../api/documents";

// types 
import { Document as DocumentType } from "../../../../types/response";
import NoDataFound from "../../../../components/NoDataFound";
import ImageBox from "./ImageBox";
import { useData } from "../../../../hooks/useUsersData";
import { t } from "i18next";
export default function Library() {
    const {
        loading,
        data,
        noData,
        refreshData,
    } = useData<DocumentType>({
        fetchData: (page, limit) => getMyDocuments(page, limit).then(res => ({
            data: [res.data.documents],
            total: res.data.total,
        })),
        errorMessage: t('Error while fetching user data') as string,
    });

    const [dropzoneState, setDropzoneState] = useState<boolean>(false);

    return (
        <>
            <PageHeading>Uplouds Library</PageHeading>
            <Button
                leftIcon={<IconCloudUpload size={16} />}
                onClick={() => setDropzoneState(!dropzoneState)}
                ml={'md'}
                mb={'md'}
            >
                Uploud
            </Button>
            {
                Boolean(dropzoneState) && (
                    <>
                        <Switch
                            label="ملف خاص"
                        />
                        <Dropzone
                            mx={'md'}
                            onUpload={() => refreshData()}
                        />
                    </>
                )
            }
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {
                    loading ? (
                        <p>Loading...</p>
                    ) : noData ? <NoDataFound /> : (
                        data.map((item: DocumentType, index) => (
                            <ImageBox
                                key={index}
                                data={item}
                            />
                        ))
                    )
                }
            </Box>
        </>
    )

}