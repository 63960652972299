// vendors
import {
    Box,
} from "@mantine/core";
import { useTranslation } from 'react-i18next';
import { useForm } from '@mantine/form';
import Axios from 'axios';

// utils
import PageHeading from "../../../../components/PageHeading";
import { useHasPermission } from '../../../../hooks';


// types
import NoPermisionPage from '../../../../components/NoPermisionPage';
import { parseError, randomString } from 'functions';
import { showNotification, updateNotification } from '@mantine/notifications';
import { IconSend } from '@tabler/icons';
import FormFields from './FormFields';
import { useEffect, useState } from "react";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
export default function Notifications() {
    const permissionGranted = useHasPermission(["super_users.read"]);
    const [linkValue, setLinkValue] = useState<string>("");
    const [firebaseToken, setFirebaseToken] = useState<string | undefined>("");
    const { t } = useTranslation();
    const firebaseConfig = {
        apiKey: "AIzaSyB0M7rlnnj1owEkpXJ1NGVZ4xiEgU2Azqg",
        authDomain: "alemtilak-58e4e.firebaseapp.com",
        projectId: "alemtilak-58e4e",
        storageBucket: "alemtilak-58e4e.firebasestorage.app",
        messagingSenderId: "928559686167",
        appId: "1:928559686167:web:e0215f153800165b96b252",
        measurementId: "G-TKZPWC279N"
    };
    const firebaseApp = initializeApp(firebaseConfig);
    async function getFirebaseToken() {
        try {
            const token = await getToken(messaging, { vapidKey: "BIWfPHrAkW3fPfxL_0tIGj5ToWO9dZQhWkIfYvRNtfZd-xU-mWp6eXRgCF2u92My1jPu5sLzA2dKDbiP5jO4vYY" });
            return token;
        } catch (error) {
            console.error("Error getting FCM token:", error);
        }
    }


    async function subscribeToTopic(token: any, topic: any) {
        try {
            const response = await Axios.post(
                `https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ya29.c.c0ASRK0GaeLxY7RM6AopvIEySICmC2ihkZQ5A3TebROsetrHiA49IC3XJF_rjx0-cR7Qk1tlqWiXUbUCeI7XAtwOq7XZEkTN39TC7x92tqFfVBQkLyCbX3T1NPBXyFJshcnD7homTS8oTeKvFBcLHG4ShlEqfzyAzi0cVaysrNP4xakQRvCUXuf0DKhElViOYGj3XzMoca3NF28zSfOYeegvPyfWtNkfv_-ZoQPW0WQ-zfyErhCYX-yLuTBl5OJLNmDaTzVjs-_cGcC56oMqfvrbb2ObCZkUnBH-J5a3VCwUD11lcYtD8wXKuF9RhRPA_5n21uGgTZuBIAH0fezAFX72YPr20GZyZhFGh4uaMkqsnIrtwlfWFr2dcCG385P2B9UoVY5BeOvSs780mpemiBo6bpkMZF7QcVYi-v4J9ded6txXgq048IlxFdU5i726zI4_5uVawg0uZ9y6lszSzWx7Bebr5-dVb7-lmRizqvYZt19jimvu1SsFYrjo_JYc3-MaScguzgl5av70X6r--Sr_l2pgoB4UvgZopV1ZJs-2jjeJuaJgBq9wmm9W0u97n8liW9iSO-bzbtsvcWk8yvgy9-R17g1M8RenqwsqUJwRFrfkWyIMuVBycovb8rYB3h0ti2s2R0zIrf8xIaQ9raW5pbi3sx7Qvekfrb2pY2a0er4SZes83vdyRmQO0Mtp3k1V6W1fzjn_3hOR5yjng5vh6BSF4i6o2JM2auxxtvaX0n0lsV5Omshrv0yUXs49QV1nWI7YJ6FV6xnkSVWS3i-jMmkbkibUZk2SBXho66g7hwh2FS8Jy2J1xmgFra1ZiwjlYOpOSwdM7aR39rO9jV8t4cFwQM6RlRmIQ9yU3_1o6nc-rYj67VVqxgaipsBoYonvOZbW4zRwVk6M97U06pXZdvqv1WVv9mWRyYh7O1IMrYbiXdYshrp-YyqWjqX8v_-Y6FcyJ4fRa3M54qo3fwy5bJuFzzi1oIqudyUUtnvBmd17VvRnkZmSp`,
                    },
                }
            );
            console.log(`Successfully subscribed to topic "${topic}":`, response.data);
        } catch (error: any) {
            console.error("Error subscribing to topic:", error.response.data);
        }
    }

    const messaging = getMessaging();

    onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
    });

    const form = useForm({
        initialValues: {
            title: '',
            body: '',
            link: '',
            id: '',
            documents: '',
        },
    });

    if (!permissionGranted) {
        return <NoPermisionPage />;
    }
    const handleSubmit = (values: any) => {
        getFirebaseToken().then((token) => {
            setFirebaseToken(token);
        }).catch((err) => {
            console.error("Error fetching access token:", err);
        });
        if (values.id !== '') {
            setLinkValue(values.link + '?search=' + values.id);
        } else {
            setLinkValue(values.link);
        }

        const id = randomString(10);
        showNotification({
            id: id,
            title: t('جاري الارسال'),
            message: t('يرجى الإنتظار...'),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });

        const payload = {
            message: {
                // to: 'f603Xd22YU5ClOBDJh-Rlb:APA91bE09Co_a29ebgbIOxgq-Vj5M0SSLZreX0ENPZ_iJ3tgXmifuEXwKbXge5BDP7-p-B5GnMGhp9k1lfLoyruVPdU_lZaBl9k_SOdu3ISWd-sF_Lo-zNk',
                token: firebaseToken,
                // topic: "all",
                notification: {
                    title: values.title,
                    body: values.body,
                    image: 'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
                },
                data: {
                    click_action: linkValue,
                    // documents: values.documents,
                },
            },
        };

        Axios.post(
            'https://fcm.googleapis.com/v1/projects/alemtilak-58e4e/messages:send',
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ya29.c.c0ASRK0GYyBj1NuvbjF62qXwqW11nILnZnoudcHMppCvPTwp09AdpCsDoEGCyeSHQULAbSZ8j8IxHzja8sHDvoV6SzpTlxStqJUjn-I9aK3nsUCcL0R9Y9yMPiXyRpCFKP3VRF0qvVHxtuKbTIkPe8jRP5lPn2WrhG17wB-QZ9J1i5ymbNSCtTEkP0HXDyLRjocLGNT8mYjEUK7N1Kal2HEz7Ttzl83ytIjDd-Nc5fYM270S5hhBzCrBIyXyLjEVNkYzt8lOtdrE3r0gp4RLWECxworpzr6rx016UnLrWly2uy4Cdfu8w3-6a0yhoIyPoBWT0QbfMrl7k0FFsQvBeXUh9fHPE2661duf7jRQAyBoCbpnV9B3ik-tTePgH387PxhsQMiiSBVhu0hUM4dbVyW8MXJ8ng8URVrZWolz3dwfnX8XnRknqMeWB38RoJV0hoiM3YrRXi420VchOOJnFMFWUzb4UaJg1afd2uoybsf399Q0Xt6lzto_517JdBZozcju9XouedddjtXpX9UiaSeX9ik7fwV_jQVbZmpv9XSk534dkJsll2W6Z8BYg7ygzydy14XtFY7plWxMrVdWMukkMB2BbXa9qgj-5_-4oVs5cf_tktdjRw0hhYMshio6WJkJ7gdlYMelIUQQ5swy3Fivmo6vn_Z1WY0VppkOiI4U4sckvF51OxtvXJnUszy5uj8mBV7fes5YwYb79vv3UYFg13uf0p3M0oruxI4UmrjI2l683BZXvnvxu1yf5dcub1M43wQwRQuZ8MzIZf03I8070YSjlq2Uhc7pgbUYa20f9nsBv_dj_9mB3djVMFqqBrSBfW15SvVYVSghyu1QoJwpfBnB5uSngm6OpR9gUOVnJR2qx2-5gyOictJa2w7jSu5MIrax4F0Q05wybzIs64VY6yl-zik6v1igOop0dMhBjcdwgeUeV1sxBoWizf3XXaexZ_sd9p1FavZhq_vd1YMziaQQqmvZ4MaB_80Mbj2U1Ys17yVZimnuI`,
                },
            }
        )
            .then(() => {
                form.reset();
                updateNotification({
                    id: id,
                    title: t('نجاح'),
                    message: t('تم ارسال الاشعار بنجاح'),
                    color: 'green',
                    icon: <IconSend />,
                    autoClose: true,
                });
            })
            .catch((err) => {

                updateNotification({
                    id: id,
                    title: t('فشل'),
                    message: typeof err === 'object' ? parseError(JSON.stringify(err)) : parseError(err),
                    color: 'red',
                    autoClose: false,
                    disallowClose: false,
                });
            });

    };


    return (
        <>
            <PageHeading>
                {t("الاشعارات")}
            </PageHeading>
            <Box
                mx={'xl'}
                my={20}
                sx={{
                    maxWidth: 600,
                }}
            >
                <FormFields
                    form={form}
                    onSubmit={handleSubmit}

                />
            </Box>
        </>
    );
}