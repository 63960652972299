// vendors
import {
    Box,
    Button,
    Center,
    Text,
} from "@mantine/core";
import dayjs from 'dayjs';
import {
    dateFilterFn,
    highlightFilterValue,
    stringFilterFn,
} from 'mantine-data-grid';
import { useTranslation } from 'react-i18next';

// utils
import PageHeading from "../../../../components/PageHeading";
import AddData from "./_AddDrawer";
import EditData from "./_EditDrawer";
import SkeletonRows from "../../../../components/SkeletonRows";
import { useHasPermission } from '../../../../hooks';

// api
import { getAds } from "../../../../api/ads";

// types
import { Ad } from "../../../../types/response";
import NoDataFound from '../../../../components/NoDataFound';
import NoPermisionPage from '../../../../components/NoPermisionPage';
import { useData } from '../../../../hooks/useUsersData';
import CustomDataGrid from 'components/DataGridComponent';
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useHighlightRow } from "hooks/useHighlightedRow";
import { IconExternalLink, IconMapPin, IconNavigation } from "@tabler/icons";
export default function Ads() {

    const permissionGranted = useHasPermission(["advertisements.read"]);
    const { t } = useTranslation();

    const {
        loading,
        data,
        noData,
        total,
        editData,
        setEditData,
        addDataDrawerState,
        setAddDataDrawerState,
        load,
        search,
        refreshData,
    } = useData<Ad>({
        fetchData: (page, limit) => getAds(page, limit).then(res => ({
            data: res.data.advertisements,
            total: res.data.total,
        })),
        errorMessage: 'Error while fetching user data',
    });
    const { search: queryString } = useLocation();
    const queryParams = new URLSearchParams(queryString);
    const [initialSearchValue, setInitial] = useState(queryParams.get("search") || "");
    const navigate = useNavigate();
    const [highlightedRowId] = useHighlightRow({
        searchValue: initialSearchValue,
        onSearch: (val) => search(val)
    })

    if (!permissionGranted) {
        return <NoPermisionPage />;
    }
    const columns = [
        {
            accessorKey: 'name',
            header: t('Name') as string,
            filterFn: stringFilterFn,
            size: 200,
            cell: highlightFilterValue,
        },
        {
            header: t('Description') as string,
            accessorKey: 'description',
            filterFn: stringFilterFn,
        },
        {
            header: t("URL") as string,
            accessorKey: 'url',
            filterFn: stringFilterFn,
        },
        {
            header: 'تاريخ الإنشاء',
            accessorKey: 'created_at',
            filterFn: dateFilterFn,
            cell: (props: any): string => props.row.original.created_at ? dayjs(props.row.original.created_at).format('DD/MM/YYYY') : '',
        },
        {
            header: 'صالح حتى',
            accessorKey: 'expired_at',
            filterFn: dateFilterFn,
            cell: (props: any): string => props.row.original.expires_at ? dayjs(props.row.original.expires_at).format('DD/MM/YYYY') : '',
        },
        {
            header: 'عرض التفاصيل',
            accessorKey: 'url',
            cell: (props: any) => (
                <Button
                    variant="default"

                    onClick={() => {

                        const targetUrl = new URL(props.row.original.url, window.location.origin);

                        const newSearchParams = new URLSearchParams(targetUrl.search);
                        const finalPath = targetUrl.pathname + '?' + newSearchParams.toString().slice(0, -3);

                        navigate(finalPath);

                    }}

                >
                    <IconExternalLink size={16} />
                    <Text mx={'xs'}>{t('انتقال')}</Text>
                </Button>
            ),
        },
    ]

    return (
        <>
            <PageHeading>
                {t("Advertisements")}
            </PageHeading>
            <Button
                mx={20}
                mb={20}
                onClick={() => setAddDataDrawerState(true)}
            >
                {t("Add")}
            </Button>
            {/* <TextInput
                px={20}
                pb={20}
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
            /> */}
            <Box
                sx={{
                    padding: '0 20px',
                }}
            >
                {
                    data && data.length > 0 ? (
                        <CustomDataGrid
                            data={data}
                            total={total}
                            loading={loading}
                            onPageChange={load}
                            onSearch={search}
                            onRowClick={(row) => setEditData(row)}
                            columns={columns}
                            getRowProps={(row) => ({
                                className: row.id === highlightedRowId ? "blinking-row" : "",
                            })}
                        />
                    ) : noData ? (
                        <NoDataFound />
                    ) : (
                        <SkeletonRows count={10} height={20} />
                    )
                }
            </Box>

            <AddData
                opened={addDataDrawerState}
                refreshData={refreshData}
                onClose={() => setAddDataDrawerState(false)}
            />

            <EditData
                data={editData}
                opened={editData !== null}
                refreshData={refreshData}
                onClose={() => setEditData(null)}
            />
        </>
    );
}