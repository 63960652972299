// vendors
import {
    Box,
    Text,
    Button,
} from "@mantine/core";

import {

    IconAlertCircle,
    IconPrinter,
} from '@tabler/icons';
import dayjs from 'dayjs';

// utils
import PageHeading from "../../../../../components/PageHeading";


// types
import { InvestmentPropertyReport } from "../../../../../types/response";
import InvestorPropertyReport from "../../../../../types/response/InvestorPropertyReport"

import { DataGridPaginationState, dateFilterFn, OnChangeCallback, stringFilterFn } from "mantine-data-grid";
import SkeletonRows from "../../../../../components/SkeletonRows";
import NoDataFound from "../../../../../components/NoDataFound";
import { useTranslation } from "react-i18next";
import { getInvestmentsReport } from "api/investment";
import { useData } from "../../../../../hooks/useUsersData";
import DataGridComponent from '../../../../../components/DataGridComponent';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PropertyDetailsModal from "./components/PropertyDetailsModal"
import Properties from "types/response/Properties";
import { parseError } from "functions";
import { showNotification } from "@mantine/notifications";
export default function Users() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const coloumn = [
        {
            accessorKey: 'from_to',
            header: t('اسم العقار') as string,
            filterFn: stringFilterFn,
            size: 150,
            cell: (props: any) => props.row.original.property_profit.name,
        },
        {
            accessorKey: 'cost',
            header: t('سعر الشراء') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => props.row.original?.property_profit?.cost ? props.row.original?.property_profit?.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null,
        },
        // {
        //     header: t('تاريخ الشراء') as string,
        //     accessorKey: 'Issue Date',
        //     filterFn: dateFilterFn,
        //     cell: (props: any): string => dayjs(props.row.original.properties.property_profit.purchase_date).format('DD/MM/YYYY'),
        // },
        {
            accessorKey: 'subject',
            header: t('سعر البيع') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => props.row.original.property_profit.sell_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
        {
            header: t('تاريخ البيع') as string,
            accessorKey: 'Issue Date',
            filterFn: dateFilterFn,
            cell: (props: any): string => dayjs(props.row.original.property_profit.selling_date).format('DD/MM/YYYY'),
        },
        {
            accessorKey: 'subject',
            header: t('الربح الكلي') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => props.row.original.property_profit.profit.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
        {
            accessorKey: 'subject',
            header: t('نسبة المستثمرين') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => (props.row.original.property_profit.investors_ratio * 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '%',
        },
        {
            accessorKey: 'subject',
            header: t('ربح المستثمرين') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => props.row.original.property_profit.investors_profit.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
        {
            accessorKey: 'subject',
            header: t('نسبة الشركة') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => (props.row.original.property_profit.company_ratio * 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '%',
        },
        {
            accessorKey: 'subject',
            header: t('ربح الشركة') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => props.row.original.property_profit.company_profit.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
        {
            accessorKey: 'subject',
            header: t('طباعة العقد') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => (
                <Button
                    variant="default"

                    onClick={() => {
                        navigate('./printForm', {
                            state: props.row.original,
                        });
                    }}
                >
                    <IconPrinter size={16} />
                    <Text mx={'xs'}>{t('طباعة')}</Text>
                </Button>
            ),
        },
    ];

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<Properties[]>([]);
    const [noData, setNoData] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [editData, setEditData] = useState<null | Properties>(null);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [searchValue, setSearchValue] = useState('');
    const loadData = async (page: number, limit: number, search: string = '') => {
        setLoading(true);
        getInvestmentsReport(page, limit, search).then(res => {
            console.log(res)

            setData(res.data.properties);
            setTotal(res.data.total);

        }).catch(err => {
            if (err.response.status === 404) {
                setNoData(true);
                setData([]);
                return;
            }
            console.error(err);
            const error = {
                title: t('Error'),
                message: parseError(err),
                color: 'red',
                icon: <IconAlertCircle />,
            }
            showNotification(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const load: OnChangeCallback<DataGridPaginationState> = async ({ pageIndex, pageSize }) => {
        setCurrentPage(pageIndex);
        await loadData(pageIndex + 1, pageSize, searchValue);
    };

    const search: OnChangeCallback<string> = async (val) => {
        if (val === searchValue) return;
        setSearchValue(val);
        await loadData(1, 10, val);
    };



    const refreshData = () => {
        load({ pageIndex: currentPage, pageSize: 10 });
    };

    useEffect(() => {
        load({ pageIndex: 0, pageSize: 10 });
    }, []);
    return (
        <>
            <PageHeading>
                {t('العقارات الاستثمارية')}
            </PageHeading>
            <Box
                sx={{
                    padding: '0 20px',
                    width: '100%',
                    height: 'calc(100vh - 151.594px)',
                }}
            >
                {
                    data ? (
                        <DataGridComponent
                            data={data}
                            total={total}
                            loading={loading}
                            onPageChange={load}
                            onSearch={search}
                            onRowClick={(row) => setEditData(row)}
                            columns={coloumn}
                        />
                    ) : noData ? (
                        <NoDataFound />
                    ) : (
                        <SkeletonRows count={10} height={20} />
                    )
                }
            </Box>
            <PropertyDetailsModal
                opened={editData !== null}
                onClose={() => setEditData(null)}
                selectedProperty={editData ? editData : null}
            />
        </>
    );
}