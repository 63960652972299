import { Button } from '@mantine/core';
import PageHeading from '../../../../../components/PageHeading';
import AddData from './_AddDrawer';
import EditData from './_EditDrawer';
import CustomDataGrid from "../shared/components/CustomDataGrid";
import { getUsers } from '../../../../../api/user';
import { User as UserType } from './../../../../../types/response';
import { useData } from '../../../../../hooks/useUsersData';
import { useTranslation } from 'react-i18next';
export default function Users() {
    const { t } = useTranslation();
    const {
        loading,
        data,
        noData,
        total,
        editData,
        setEditData,
        addDataDrawerState,
        setAddDataDrawerState,
        load,
        search,
        refreshData,
    } = useData<UserType>({
        fetchData: (page, limit, search) => getUsers(page, limit, search).then(res => ({
            data: res.data.accounts,
            total: res.data.total,
        })),
        errorMessage: t('Error while fetching user data') as string,
    });

    return (
        <>
            <PageHeading>{t('Employees')}</PageHeading>
            <Button mx={20} mb={20} onClick={() => setAddDataDrawerState(true)}>
                {t('Add')}
            </Button>
            <CustomDataGrid
                data={data}
                total={total}
                loading={loading}
                noData={noData}
                load={load}
                search={search}
                setEditData={setEditData}
                isEmployee={true}
            />
            <AddData
                opened={addDataDrawerState}
                refreshData={refreshData}
                onClose={() => setAddDataDrawerState(false)}
            />
            <EditData
                data={editData}
                opened={editData !== null}
                refreshData={refreshData}
                onClose={() => setEditData(null)}
            />
        </>
    );
}