import axios, { CancelTokenSource } from 'axios';
import dayjs from "dayjs";
import { apiWithInterceptor } from ".";

let cancelTokens: { [key: string]: CancelTokenSource } = {};
// get



export const getInvoices = (page: number = 1, limit: number = -1, search: string = '', params?: { [key: string]: string }) => {
    cancelTokens.getInvoices && cancelTokens.getInvoices.cancel();
    cancelTokens.getInvoices = axios.CancelToken.source();
    return apiWithInterceptor.get(`/invoice/`, { params: { page, limit, search, ...params } },);
}
export const getInvoicesMovements = (page: number = 1, limit: number = -1, search: string = '', params?: { [key: string]: string }) => {
    cancelTokens.getInvoices && cancelTokens.getInvoices.cancel();
    cancelTokens.getInvoices = axios.CancelToken.source();
    return apiWithInterceptor.get(`/invoice/?exclude[invoice_type]=investment,customer_withdrawal`, { params: { page, limit, search, ...params } },);
}
export const getInvoice = (id: string) => apiWithInterceptor.get(`/invoice/${id}`)
export const getInvoiceReport = (since: Date, until: Date) => apiWithInterceptor.get(`/invoice/report`, {
    params: {
        empty_array: 1,
        since: dayjs(since).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        until: dayjs(until).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    }
})

// post
export const createInvoice = (data: {}) => apiWithInterceptor.post(`/invoice/`, { ...data })
export const createBankInvoice = (data: {}) => apiWithInterceptor.post(`/invoice/`, { ...data, asset_type: "bank" })
export const createCashInvoice = (data: {}) => apiWithInterceptor.post(`/invoice/`, { ...data, asset_type: "cash" })
// delete
export const deleteInvoice = (id: string) => apiWithInterceptor.delete(`/invoice/${id}`, { params: { approved: true } })
// put
export const updateInvoice = (id: string, data: any) => apiWithInterceptor.put(`/invoice/${id}`, {
    ...data,
    ...(data.issued_at ? { issued_at: dayjs(data.issued_at).format('YYYY-MM-DD') } : {}),
})