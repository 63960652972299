// vendors/FormFields.tsx
import {
    Button,
    Grid,
    Select,
    TextInput,
    Textarea,
} from '@mantine/core';
import MediaLibraryField from 'components/MediaLibraryField';
import React from 'react';
import { useTranslation } from 'react-i18next';
interface FormFieldsProps {
    form: any;
    onSubmit: (values: any) => void;
}


export const getMainLinksData = (t: any) => [
    {
        label: t('Dashboard'),
        link: '/app/dashboard',
    },
    {
        label: t('Employees'),
        link: '/app/users/employees',
    },
    {
        label: t('Letters'),
        link: '/app/letters',
    },
    {
        label: t('Cities'),
        link: '/app/users/cities',
    },
    {
        label: t('Map'),
        link: '/app/sales/map',
    },
    {
        label: t('Properties'),
        link: '/app/sales/properties',
    },
    {
        label: t('Projects'),
        link: '/app/sales/projects',
    },
    {
        label: t('Contracts'),
        link: '/app/contracts',
    },
    {
        label: t('جدول الاستثمارات'),
        link: '/app/investment/overview',
    },
    {
        label: t('المستثمرين'),
        link: '/app/investment/investors',
    },
    {
        label: t('العقارات الإستثمارية'),
        link: '/app/investment/investments',
    },
    {
        label: t('السحوبات'),
        link: '/app/investment/tableout',
    },
    {
        label: t('الايداعات'),
        link: '/app/investment/tablein',
    },
    {
        label: t('Finance'),
        link: '/app/finance',
    },
    {
        label: t('Overview'),
        link: '/app/finance/overview',
    },
    {
        label: t('الحركات المالية'),
        link: '/app/finance/invoices/movements',
    },
    {
        label: t('جميع الفواتير'),
        link: '/app/finance/invoices',
    },
    {
        label: t('Customers'),
        link: '/app/users/customers',
    },
    {
        label: t('Advertising'),
        link: '/app/ads',
    },
    {
        label: t('Utilities'),
        link: '/app/utilities',
    },
    {
        label: t('مقدمي الخدمة'),
        link: '/app/utilities/vendors',
    },
    {
        label: t('الفئات'),
        link: '/app/utilities/vendors/categories',
    },
    {
        label: t('Inventory'),
        link: '/app/inventory',
    },
    {
        label: t('Activity Logs'),
        link: '/app/logs',
    },
    {
        label: t('الاشعارات'),
        link: '/app/notifications',
    },
];



const FormFields: React.FC<FormFieldsProps> = ({ form, onSubmit }) => {
    const { t } = useTranslation();
    const links = getMainLinksData(t).map((item) => ({
        label: item.label,
        value: `http://system.alemtilak.com${item.link}`,
    }));
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Grid
                gutter={20}
            >
                <Grid.Col span={12}>
                    <TextInput
                        placeholder={t("عنوان الاشعار")}
                        label={t("عنوان الاشعار")}
                        required
                        style={{ width: '100%' }}
                        {...form.getInputProps('title')}
                    />
                </Grid.Col>
                <>
                    <Grid.Col span={6}>
                        <Select
                            label={t('اختر النافذة')}
                            placeholder={t('اختر النافذة')}
                            data={links}
                            {...form.getInputProps('link')}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <TextInput
                            placeholder={t("اضف معرف العنصر")}
                            label={t("اضف معرف العنصر")}
                            style={{ width: '100%' }}
                            {...form.getInputProps('id')}
                        />
                    </Grid.Col>
                </>
                <Grid.Col span={12}>
                    <Textarea
                        placeholder={t("نص الاشعار")}
                        label={t("نص الاشعار")}
                        required
                        style={{ width: '100%' }}
                        {...form.getInputProps('body')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <MediaLibraryField
                        inputProps={{
                            size: "xs",
                            label: t("Documents"),
                            placeholder: t("Select documents"),
                        }}
                        multiple={true}
                        {...form.getInputProps('documents')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Button
                        color="blue"
                        type="submit"
                    >
                        {t("ارسال")}
                    </Button>
                </Grid.Col>
            </Grid>

        </form>
    );
};

export default FormFields;