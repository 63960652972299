import React from 'react';
import { Grid, TextInput, Button, LoadingOverlay, ScrollArea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconSend } from '@tabler/icons';
import { useTranslation } from 'react-i18next';


type CityFormProps = {
    initialValues: { category: string };
    onSubmit: (values: any, resetForm: () => void) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    submitLabel: string;
};

const CategoryForm: React.FC<CityFormProps> = ({ initialValues, onSubmit, loading, setLoading, submitLabel }) => {
    const { t } = useTranslation();
    const form = useForm({
        initialValues,
    });

    return (
        <ScrollArea offsetScrollbars
            style={{ height: 'calc(100vh - 117px)' }}>
            <form
                onSubmit={form.onSubmit((values) => onSubmit(values, form.reset))}
            >
                <Grid gutter="lg" mt={20}>
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Name")}
                            placeholder={t("Name")}
                            {...form.getInputProps("category")}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Description")}
                            placeholder={t("Description")}
                            {...form.getInputProps('description')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Button
                            type='submit'
                            size="xs"
                            leftIcon={<IconSend size={16} />}
                        >
                            {submitLabel}
                        </Button>
                    </Grid.Col>
                </Grid>
            </form>
        </ScrollArea>
    );
};

export default CategoryForm;
