import { Button } from "@mantine/core";
import { useNavigate } from 'react-router-dom';
interface FormFieldsProps {
    log: any;
    index: any;
}

const ShowDialog: React.FC<FormFieldsProps> = ({ log, index }) => {
    const navigate = useNavigate();
    const handleShowDetials = () => {
        console.log(log.item_type);
        if (log.item_type === 'invoice') {
            navigate(`/app/finance/invoices?search=${log.item_id}`);
        }
        else if (log.item_type === 'property') {

            navigate(`/app/sales/properties?search=${log.item_id}`);
        }
        else if (log.item_type === 'inventory') {
            navigate(`/app/inventory?search=${log.item_id}`);
        }
        else if (log.item_type === 'contact') {
            navigate(`/app/contracts?search=${log.item_id}`);
        }
        else if (log.item_type === 'letter') {
            navigate(`/app/letters?search=${log.item_id}`);
        }
        else if (log.message.includes('مدينة')) {
            navigate(`/app/users/cities?search=${log.item_id}`);
        }
        else if (log.item_type === 'advertisements') {
            navigate(`/app/ads?search=${log.item_id}`);
        }
        else if (log.item_type === 'project') {
            navigate(`/app/sales/projects?search=${log.item_id}`);
        }
        else if (log.item_type === 'customer') {
            navigate(`/app/investment/investors?search=${log.item_id}`);
        }

    };
    return <Button onClick={handleShowDetials}>عرض التفاصيل</Button>;

}
export default ShowDialog;