import { useRef, useState } from 'react';
import { Container, TextInput, Textarea } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// types
import { Contract as ContractType } from '../../../../types/response';
import { useForm } from '@mantine/form';

import FormBuy from '../../../../assets/form-buy.png';
import FormRent from '../../../../assets/form-rent.png';
import { DndContext } from '@dnd-kit/core';
import { TFunction } from 'i18next';
import dayjs from 'dayjs';
import { PrintButton } from '../../../../components/PrintButton';
import { useDetectPrint } from '../../../../hooks/useDetectPrint';
import { handleDragEnd, convertToAbsolute } from '../../../../utilty/utils';
import DraggableField from '../../../../components/DraggableField';
import PrintHeader from '../../../../components/PrintHeader';
// import { IFieldMetadata } from '../../../../types/printTypes/types';

interface IFieldState {
  id: string;
  dragId: string;
  transform: {
    x: number;
    y: number;
    percentX: number;
    percentY: number;
  };
}


interface IFieldMetadata {
  [key: string]: {
    label: { sell: string; rent: string };
    placeholder: { sell: string; rent: string };
    type: 'TextInput' | 'Textarea';
    style?: React.CSSProperties;
    initialPositions: {
      sell: IFieldState['transform'][];
      rent: IFieldState['transform'][];
    };
  };
}

export default function Print() {
  const location = useLocation();
  const { t } = useTranslation();
  const printContainerRef = useRef<HTMLDivElement>(null);
  const printContractRef = useRef<HTMLImageElement>(null);
  const [printContractLoaded, setPrintContractLoaded] = useState(false);
  const isPrinting = useDetectPrint();

  const contract: ContractType = location.state as ContractType;
  const type = contract.contract_type === 'rent' ? 'rent' : 'sell';
  // const [type, toggleType] = useToggle(["sell", "rent"]);

  const form = useForm({
    initialValues: {
      num_id: contract.num_id,
      sellerPrice: contract.price,
      sellerName: '',
      buyerName: contract.customer.full_name ? contract.customer.full_name : '-',
      sellerAddress: '',
      sellerType: '',
      sellerNumber: '',
      sellerVillage: '',
      sellerDeposit: '',
      sellerGuarantee: '',
      buyerAddress: contract.customer.address ? contract.customer.address : '-',
      buyerGuarantee: '',
      thirdParty: '',
      contractLocation: '',
      additional: '',
      contractDate: dayjs().format('YYYY-MM-DD'),
    },
  });

  const fieldMetadata: IFieldMetadata = getFieldMetadata(t);

  const [fields, setFields] = useState<IFieldState[]>(
    Object.keys(form.values)
      .map((key) =>
        fieldMetadata[key].initialPositions[type].map((initialPosition, index) => ({
          id: key,
          dragId: `${key}-${index}`,
          transform: {
            x: initialPosition.x,
            y: initialPosition.y,
            percentX: initialPosition.percentX,
            percentY: initialPosition.percentY,
          },
        }))
      )
      .flat()
  );

  return (
    <DndContext onDragEnd={(ev) => handleDragEnd(ev, fields, setFields, printContractRef)}>
      <Container
        m={0}
        px={0}
        pt={isPrinting ? 0 : 50}
        style={{ display: 'flex', boxSizing: 'border-box', alignItems: 'center', flexDirection: 'column', minHeight: '100vh', width: '100vw', maxWidth: '100%' }}>
        {!isPrinting && <PrintHeader data={t('Print Contract')} />}
        <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
          <Container p={0} m={0} id="print-area" ref={printContainerRef} style={{ position: 'relative', width: '210mm', height: '297mm' }}>
            <img
              ref={printContractRef}
              onLoad={() => setPrintContractLoaded(true)}
              style={{ maxHeight: '100%', maxWidth: '100%', position: 'absolute', top: 0, right: 0, border: isPrinting ? "" : "2px solid black" }}
              src={type === 'sell' ? FormBuy : FormRent}
              alt=""
            />
            {printContractLoaded &&
              fields.map((field, ind) => {

                return (
                  <div
                    style={{
                      position: 'absolute',
                      transform: `translate3d(${convertToAbsolute(
                        printContractRef.current?.scrollWidth ?? 0,
                        0, // Always aligning to right (printContractRef.current?.offsetLeft ?? 0,)
                        field.transform.percentX //
                      )}px, ${convertToAbsolute(
                        printContractRef.current?.scrollHeight ?? 0,
                        0, // Always aligning to top (printContractRef.current?.offsetTop ?? 0),
                        field.transform.percentY //
                      )}px, 0)`,
                    }}
                    key={field.dragId}>
                    {isPrinting ? (
                      // @ts-ignore
                      <p style={{ transform: 'translate(-20px,-10px)', whiteSpace: 'pre-line', lineHeight: '175%' }}>{form.values[field.id]}</p>
                    ) : (
                      <DraggableField id={field.dragId}>
                        {fieldMetadata[field.id].type === 'TextInput' ? (
                          <TextInput style={fieldMetadata[field.id].style} size={'xs'} placeholder={fieldMetadata[field.id].placeholder[type]} {...form.getInputProps(field.id)} />
                        ) : fieldMetadata[field.id].type === 'Textarea' ? (
                          <Textarea
                            size={'xs'}
                            placeholder={fieldMetadata[field.id].placeholder[type]}
                            label={fieldMetadata[field.id].label[type]}
                            {...form.getInputProps(field.id)}
                          />
                        ) : (
                          <p>Unhandled Field Type</p>
                        )}
                      </DraggableField>
                    )}
                  </div>
                );
              })}

          </Container>
        </div>
        {!isPrinting && <PrintButton />}
      </Container>
    </DndContext>
  );
}


const getFieldMetadata = (t: TFunction): IFieldMetadata => ({
  sellerPrice: {
    label: {
      sell: t('بدل البيع المتفق عليه'),
      rent: t('بدل الايجار المتفق عليه'),
    },
    placeholder: {
      sell: t('بدل البيع المتفق عليه'),
      rent: t('بدل الايجار المتفق عليه'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -132.711181640625, y: 313.6001281738281, percentX: -0.2299749422644807, percentY: 0.29473696256938736
        },
      ],
      rent: [
        {
          x: -199.9111328125,
          y: 324.00006103515625,
          percentX: -0.25177724535579343,
          percentY: 0.3057701078744708,
        },
      ],
    },
  },
  num_id: {
    label: {
      sell: t('العدد'),
      rent: t('العدد'),
    },
    style: {
      fontSize: "17px",
      fontFamily: 'Tajawal',
      width: "50px",
    },
    placeholder: {
      sell: t('العدد'),
      rent: t('العدد'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -590.5600891113281,
          y: 100.709002502441406,
          percentX: -0.7525894944505667,
          percentY: 0.10462750320298574,
        },
      ],
      rent: [
        {
          x: -590.5600891113281,
          y: 100.709002502441406,
          percentX: -0.7525894944505667,
          percentY: 0.10462750320298574,
        },
      ],
    },
  },

  sellerName: {
    label: {
      sell: t('اسم البائع'),
      rent: t('اسم المؤجر'),
    },
    placeholder: {
      sell: t('اسم البائع'),
      rent: t('اسم المؤجر'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -89.86672973632812, y: 226.22223663330078, percentX: -0.17292507288459139, percentY: 0.21261488405385412
        },
        {
          x: -72.62237548828125, y: 853.7779541015625, percentX: -0.14996321636255824, percentY: 0.8024228891931978
        },
        {
          x: -100.1777613066406, y: 153.33329010009766, percentX: -0.18665480866396886, percentY: 0.14411023505648277
        },
      ],
      rent: [
        {
          x: -85.8668212890625, y: 224.62226189208985, percentX: -0.1675989631012816, percentY: 0.21111114839482129
        },
        {
          x: -119.8223876953125, y: 900.177978515625, percentX: -0.15090980818049432, percentY: 0.852297663561163
        },
        {
          x: -103.37783792773439, y: 153.33329010009766, percentX: -0.19091589604225617, percentY: 0.14411023505648277
        },
      ],
    },
  },
  buyerName: {
    label: {
      sell: t('اسم المشتري'),
      rent: t('اسم المستأجر'),
    },
    placeholder: {
      sell: t('اسم المشتري'),
      rent: t('اسم المستأجر'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -108.08880615234375, y: 412.44447326660156, percentX: -0.1971888231056508, percentY: 0.3876357831453022
        },
        {
          x: -126.84454345703125, y: 175.7333221435547, percentX: -0.22216317371109354, percentY: 0.16516289675146117
        },
        {
          x: -454.755615234375, y: 899.2889556884766, percentX: -0.572740069564704, percentY: 0.8015053081002464
        },
      ],
      rent: [
        {
          x: -184.8887939453125,
          y: 500.4445037841797,
          percentX: -0.19923361416520743,
          percentY: 0.3814363595916004,
        },
        {
          x: -212.444580078125,
          y: 213.3333282470703,
          percentX: -0.22892734922211747,
          percentY: 0.1626016221395353,
        },
        {
          x: -451.5555419921875,
          y: 873.6888580322266,
          percentX: -0.5687097506198835,
          percentY: 0.778688821775603,
        },
      ],
    },
  },

  sellerAddress: {
    label: {
      sell: t('عنوان البائع'),
      rent: t('عنوان المؤجر'),
    },
    placeholder: {
      sell: t('عنوان البائع'),
      rent: t('عنوان المؤجر'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -396.2665710449219, y: 222.84451293945312, percentX: -0.5809142091144099, percentY: 0.20944033171001233
        },
        {
          x: -119.7333984375,
          y: 930.31103515625,
          percentX: -0.15079773102959698,
          percentY: 0.8291542202818627,
        },
      ],
      rent: [
        {
          x: -457.8665771484375,
          y: 226.84451293945312,
          percentX: -0.5766581576176795,
          percentY: 0.20217871028471757,
        },
        {
          x: -124.5333251953125,
          y: 903.1111450195312,
          percentX: -0.15684297883540618,
          percentY: 0.8049118939568015,
        },
      ],
    },
  },
  sellerType: {
    label: {
      sell: t('نوع البائع'),
      rent: t('نوع المؤجر'),
    },
    placeholder: {
      sell: t('نوع البائع'),
      rent: t('نوع المؤجر'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -24.622497730468694, y: 249.2444151796875, percentX: -0.0860485988421687, percentY: 0.23425226990572134
        },
      ],
      rent: [
        {
          x: -73.42248552343744,
          y: 252.44445821484373,
          percentX: -0.09247164423606731,
          percentY: 0.22499506079754344,
        },
      ],
    },
  },
  sellerNumber: {
    label: {
      sell: t('الرقم والتسلسل'),
      rent: t('الرقم والتسلسل'),
    },
    placeholder: {
      sell: t('الرقم والتسلسل'),
      rent: t('الرقم والتسلسل'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -94.13330078125, y: 271.1999969482422, percentX: -0.17860625936251665, percentY: 0.25488721517691937
        },
      ],
      rent: [
        {
          x: -142.13330078125,
          y: 276.7999725341797,
          percentX: -0.179009194938602,
          percentY: 0.24670229281121184,
        },
      ],
    },
  },
  sellerVillage: {
    label: {
      sell: t('المحلة'),
      rent: t('المحلة'),
    },
    placeholder: {
      sell: t('المحلة'),
      rent: t('المحلة'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -30.93316650390625, y: 298.84442138671875, percentX: -0.09445161984541445, percentY: 0.2808688170927808
        },
      ],
      rent: [
        {
          x: -85.333251953125,
          y: 356.4444580078125,
          percentX: -0.09195393529431574,
          percentY: 0.271680227140101,
        },
      ],
    },
  },
  sellerDeposit: {
    label: {
      sell: t('العربون الذي تم قبضه'),
      rent: t('العربون الذي تم قبضه'),
    },
    placeholder: {
      sell: t('العربون الذي تم قبضه'),
      rent: t('العربون الذي تم قبضه'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -141.51123046875, y: 339.02220153808594, percentX: -0.24169271700233022, percentY: 0.3186298886636146
        },
      ],
      rent: [
        {
          x: -201.51123046875,
          y: 348.6222381591797,
          percentX: -0.2537924816986776,
          percentY: 0.31071500727199614,
        },
      ],
    },
  },
  sellerGuarantee: {
    label: {
      sell: t('التضمينات التي تدفع للمشتري في حال عدم الإلتزام'),
      rent: t('التضمينات التي تدفع للمستأجر في حال عدم الإلتزام'),
    },
    placeholder: {
      sell: t('التضمينات التي تدفع للمشتري في حال عدم الإلتزام'),
      rent: t('التضمينات التي تدفع للمستأجر في حال عدم الإلتزام'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -247.28887939453125, y: 390.3111114501953, percentX: -0.3825417834813998, percentY: 0.36683375136296553
        },
      ],
      rent: [
        {
          x: -55.288818359375,
          y: 398.3110809326172,
          percentX: -0.06963327249291562,
          percentY: 0.35500096339805454,
        },
      ],
    },
  },
  buyerAddress: {
    label: {
      sell: t('عنوان المشتري'),
      rent: t('عنوان المستأجر'),
    },
    placeholder: {
      sell: t('عنوان المشتري'),
      rent: t('عنوان المستأجر'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -38.1334228515625, y: 436.1778106689453, percentX: -0.10403917823110852, percentY: 0.4099415513805877
        },
        {
          x: -454.8446044921875, y: 930.3111572265625, percentX: -0.5728521467156014, percentY: 0.8291543290789327
        },
      ],
      rent: [
        {
          x: -93.3333740234375,
          y: 529.7777862548828,
          percentX: -0.10057475649077317,
          percentY: 0.4037940444015875,
        },
        {
          x: -455.64453125,
          y: 902.3111572265625,
          percentX: -0.5738596111460957,
          percentY: 0.80419889235879,
        },
      ],
    },
  },
  buyerGuarantee: {
    label: {
      sell: t('التضمينات التي تدفع للبائع في حال عدم الإلتزام'),
      rent: t('التضمينات التي تدفع للمؤجر في حال عدم الإلتزام'),
    },
    placeholder: {
      sell: t('التضمينات التي تدفع للبائع في حال عدم الإلتزام'),
      rent: t('التضمينات التي تدفع للمؤجر في حال عدم الإلتزام'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -239.4666748046875, y: 531.8222198486328, percentX: -0.3721260649862683, percentY: 0.4998329133915722
        },
      ],
      rent: [
        {
          x: -52.2667236328125,
          y: 545.4221649169922,
          percentX: -0.0658271078498898,
          percentY: 0.48611601151247075,
        },
      ],
    },
  },
  thirdParty: {
    label: {
      sell: t('الطرف الثالث المتعهد'),
      rent: t('الطرف الثالث المتعهد'),
    },
    placeholder: {
      sell: t('الطرف الثالث المتعهد'),
      rent: t('الطرف الثالث المتعهد'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -82.22210693359375, y: 575.2888641357422, percentX: -0.16274581482502495, percentY: 0.5406850226839682
        },
      ],
      rent: [
        // TODO: No thirdparty in rent?
        // {
        //   x: -606.22216796875,
        //   y: 32.888916015625,
        //   percentX: -0.6532566465180496,
        //   percentY: 0.025067771353372712,
        // },
      ],
    },
  },
  contractLocation: {
    label: {
      sell: t('تم تحرير هذا العقد في'),
      rent: t('تم تحرير هذا العقد في'),
    },
    placeholder: {
      sell: t('تم تحرير هذا العقد في'),
      rent: t('تم تحرير هذا العقد في'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -9.68896484375, y: 668.6223297119141, percentX: -0.06616373481191744, percentY: 0.6284044452179643
        },
      ],
      rent: [
        {
          x: -40.0888671875,
          y: 670.2223052978516,
          percentX: -0.05048975716309824,
          percentY: 0.5973460831531654,
        },
      ],
    },
  },
  additional: {
    label: {
      sell: t('الفقرات الاضافية'),
      rent: t('الفقرات الاضافية'),
    },
    placeholder: {
      sell: t('الفقرات الاضافية'),
      rent: t('الفقرات الاضافية'),
    },
    type: 'Textarea',
    initialPositions: {
      sell: [
        {
          x: -58.48876953125,
          y: 737.8666076660156,
          percentX: -0.07366343769678842,
          percentY: 0.6576351226969837,
        },
      ],
      rent: [
        {
          x: -59.2886962890625,
          y: 731.4665832519531,
          percentX: -0.07467090212728275,
          percentY: 0.6519310011158227,
        },
      ],
    },
  },
  contractDate: {
    label: {
      sell: t('تاريخ العقد'),
      rent: t('تاريخ العقد'),
    },
    placeholder: {
      sell: t('تاريخ العقد'),
      rent: t('تاريخ العقد'),
    },
    type: 'TextInput',
    initialPositions: {
      sell: [
        {
          x: -482.4887390136719, y: 667.4666137695312, percentX: -0.6957240199915737, percentY: 0.6273182460239956
        },
        {
          x: -136.88873291015625, y: 945.8666687011719, percentX: -0.2355375937552014, percentY: 0.8889724329898232
        },
      ],
      rent: [
        {
          x: -524.0888061523438,
          y: 673.0665588378906,
          percentX: -0.6600614687057226,
          percentY: 0.5998810684829685,
        },
        {
          x: -194.48876953125,
          y: 963.4666442871094,
          percentX: -0.2449480724574937,
          percentY: 0.8587046740526821,
        },
      ],
    },
  },
});
