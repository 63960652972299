// vendors
import {
    Box,
    Button,
} from "@mantine/core";
import dayjs from 'dayjs';

import {
    dateFilterFn,
    highlightFilterValue,
    stringFilterFn,
} from 'mantine-data-grid';

// utils
import PageHeading from "../../../../../components/PageHeading";
import AddData from "./_AddDrawer";
import EditData from "./_EditDrawer";
import SkeletonRows from "../../../../../components/SkeletonRows";
import NoDataFound from '../../../../../components/NoDataFound';

// api
import { getProjects } from "../../../../../api/property";

// types
import { Project as ProjectType } from "../../../../../types/response";
import { useTranslation } from 'react-i18next';
import { useData } from '../../../../../hooks/useUsersData';
import DataGridComponent from '../../../../../components/DataGridComponent';
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useHighlightRow } from "hooks/useHighlightedRow";

export default function Customers() {
    const { t } = useTranslation();

    const {
        loading,
        data,
        noData,
        total,
        editData,
        setEditData,
        addDataDrawerState,
        setAddDataDrawerState,
        load,
        search,
        refreshData,
    } = useData<ProjectType>({
        fetchData: (page, limit, search) => getProjects(page, limit, search).then(res => ({
            data: res.data.projects,
            total: res.data.total,
        })),
        errorMessage: t('Error while fetching user data') as string,
    });
    const { search: queryString } = useLocation();
    const queryParams = new URLSearchParams(queryString);
    const [initialSearchValue, setInitial] = useState(queryParams.get("search") || "");

    const [highlightedRowId] = useHighlightRow({
        searchValue: initialSearchValue,
        onSearch: (val) => search(val)
    })
    const column = [
        {
            accessorKey: 'name',
            header: t('Name') as string,
            filterFn: stringFilterFn,
            size: 300,
            cell: highlightFilterValue,
        },
        {
            header: t('Address') as string,
            accessorKey: 'address',
            filterFn: stringFilterFn,
        },
        {
            header: t('Date') as string,
            accessorKey: 'created_at',
            filterFn: dateFilterFn,
            cell: (props: any): string => dayjs(props.row.original.created_at).format('DD/MM/YYYY'),
        },
    ];
    return (
        <>
            <PageHeading>
                {t('Projects')}
            </PageHeading>
            <Button
                mx={20}
                mb={20}
                onClick={() => setAddDataDrawerState(true)}
            >
                {t("Add")}
            </Button>
            {/* <TextInput
                px={20}
                pb={20}
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
            /> */}
            <Box
                sx={{
                    padding: '0 20px',
                }}
            >
                {
                    data ? (
                        <DataGridComponent
                            data={data}
                            total={total}
                            loading={loading}
                            onPageChange={load}
                            onSearch={search}
                            onRowClick={(row) => setEditData(row)}
                            columns={column}
                            getRowProps={(row) => ({
                                className: row.id === highlightedRowId ? "blinking-row" : "",
                            })}
                        />
                    ) : noData ? (
                        <NoDataFound />
                    ) : (
                        <SkeletonRows count={10} height={20} />
                    )
                }
            </Box>

            <AddData
                opened={addDataDrawerState}
                refreshData={refreshData}
                onClose={() => setAddDataDrawerState(false)}
            />

            <EditData
                data={editData}
                opened={editData !== null}
                refreshData={refreshData}
                onClose={() => setEditData(null)}
            />
        </>
    );
}