import { useState } from 'react';
import { useForm } from '@mantine/form';
import { IFieldMetadata } from "../types/printingTypes/types";
export interface InitialPosition {
  x: number;
  y: number;
  percentX: number;
  percentY: number;
}


export function useFormFields(initialValues: any, fieldMetadata: IFieldMetadata, type: string) {
  console.log(initialValues)
  const form = useForm({
    initialValues,
  });

  const [fields, setFields] = useState(
    Object.keys(form.values)
      .filter((key) => fieldMetadata[key])
      .map((key) =>
        fieldMetadata[key].initialPositions[type].map((initialPosition: InitialPosition, index: number) => ({
          id: key,
          dragId: `${key}-${index}`,
          transform: {
            x: initialPosition.x,
            y: initialPosition.y,
            percentX: initialPosition.percentX,
            percentY: initialPosition.percentY,

          },

        }))
      )
      .flat()
  );

  return { form, fields, setFields };
}
