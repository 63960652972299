// vendors
import { useEffect, useState } from 'react';
import {
    Box,
    Text,
    Skeleton,
    Timeline,
} from "@mantine/core";
import { DateRangePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import ShowDialog from './dialogDetial';
// utils
import PageHeading from "../../../../components/PageHeading";
import { useHasPermission } from '../../../../hooks';
import { parseError } from "../../../../functions";
import Visible from "../../../../components/Visible";

// api
import { getActivity } from "../../../../api/logs";

// types
import { Log } from "../../../../types/response";
import NoPermisionPage from '../../../../components/NoPermisionPage';

export default function Ads() {

    const permissionGranted = useHasPermission(["super_users.read"]);

    const [date, setDate] = useState<[Date, Date]>(() => {
        const today = new Date();
        const tommorw = new Date();
        today.setDate(today.getDate() - 1);
        return [today, tommorw];
    });

    const [data, setData] = useState<Log[]>([]);
    const [page, setPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const load = () => {
        setLoading(true);
        if (date[0] && date[1] && date[0].getTime() <= date[1].getTime()) {
            getActivity(date[0], date[1], page, 100, 1).then((res) => {
                setLoading(false);
                setData(d => [...d, ...res.data.logs]);
                if (res.data.logs.length < 30) {
                    setHasMore(false);
                }
            }).catch((err) => {
                showNotification({
                    title: t("Error"),
                    message: parseError(err),
                    color: "red",
                    icon: <IconX />,
                    autoClose: false,
                });
            });
        }
    }

    useEffect(() => {
        permissionGranted && load();
    }, [page]);

    useEffect(() => {
        if (!permissionGranted) return;
        setData([]);
        setPage(1);
        setHasMore(true);
        load();
    }, [date]);

    if (!permissionGranted) {
        return <NoPermisionPage />;
    }

    return (
        <>
            <PageHeading>
                {t("Activity Logs")}
            </PageHeading>
            <Box mx={'xl'} my={20}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        direction: 'ltr',
                    }}
                >
                    {/* <UnstyledButton
                        p={'xs'}
                        onClick={() => setDate(dayjs(date).add(1, 'day').toDate())}
                    >
                        <IconChevronRight size={20} />
                    </UnstyledButton> */}
                    <DateRangePicker
                        locale='ar'
                        value={[date[0], date[1]]}
                        clearable={false}
                        sx={{
                            '& *': {
                                textAlign: 'center',
                            }
                        }}
                        onChange={(value) => {
                            setDate(value as [Date, Date]);
                        }}



                    />
                    {/* <UnstyledButton
                        p={'xs'}
                        onClick={() => setDate(dayjs(date).subtract(1, 'day').toDate())}
                    >
                        <IconChevronLeft size={20} />
                    </UnstyledButton> */}
                </Box>
            </Box>
            <Box mx={'xl'} my={20}>

                <Timeline bulletSize={24} lineWidth={2} >
                    {
                        data && data.map((item: Log, index: number) => (

                            <Timeline.Item
                                key={index}
                                bullet={(
                                    <Box
                                        sx={theme => ({
                                            width: 10,
                                            height: 10,
                                            borderRadius: '50%',
                                            backgroundColor: item.message.includes('ببيع') ? theme.colors.blue[5] :
                                                item.message.includes('انشاء') ? theme.colors.teal[5] :
                                                    item.message.includes('بتعديل') ? theme.colors.yellow[5] :
                                                        item.message.includes('بحذف') ? theme.colors.red[6] :
                                                            theme.colors.gray[5],
                                        })}
                                    />
                                )}

                                title={i18n.language === 'ar' ?
                                    item.message
                                    :
                                    item.message}

                            >
                                <Text color="dimmed" size="sm">{item.username}</Text>
                                <Text size="xs" mt={4}>{dayjs(item.request_time).format('YYYY/MM/DD | HH:mm')}</Text>
                                <ShowDialog index={index}
                                    log={item} />
                            </Timeline.Item>


                        ))
                    }
                    {
                        hasMore && (
                            <Timeline.Item bullet={<Skeleton width={10} height={10} radius={'xl'} />} title={<Skeleton width={200} height={20} />}>
                                <Text color="dimmed" size="sm"><Skeleton width={300} height={10} /></Text>
                                <Text size="xs" mt={4}><Skeleton width={100} height={10} /></Text>
                                <Visible
                                    onVisibilityChange={(visible) => {
                                        if (visible && !loading) {
                                            setPage((current) => current + 1);
                                        }
                                    }}
                                />
                            </Timeline.Item>
                        )
                    }
                    {
                        hasMore && (
                            [...Array(10)].map((_, index) => (
                                <Timeline.Item key={index} bullet={<Skeleton width={10} height={10} radius={'xl'} />} title={<Skeleton width={200} height={20} />}>
                                    <Text color="dimmed" size="sm"><Skeleton width={300} height={10} /></Text>
                                    <Text size="xs" mt={4}><Skeleton width={100} height={10} /></Text>
                                </Timeline.Item>
                            ))
                        )
                    }
                </Timeline>
            </Box>
        </>
    );
}