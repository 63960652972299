// vendors
import { Button } from "@mantine/core";


// utils
import PageHeading from "../../../../../components/PageHeading";
import AddData from "./_AddDrawer";
import EditData from "./_EditDrawer";

// api
import { getCustomers } from "../../../../../api/customer";

// types
import { User as UserType } from "../../../../../types/response";
import { t } from 'i18next';


import CustomDataGrid from '../shared/components/CustomDataGrid';
import { useData } from '../../../../../hooks/useUsersData';
export default function Customers() {

    const {
        loading,
        data,
        noData,
        total,
        editData,
        setEditData,
        addDataDrawerState,
        setAddDataDrawerState,
        load,
        search,
        refreshData
    } = useData<UserType>({
        fetchData: (page, limit, search) => getCustomers(page, limit).then(res => ({
            data: res.data.customers,
            total: res.data.total,
        })),
        errorMessage: t('Error while fetching customer data') as string,
    });
    console.log(data);
    return (
        <>
            <PageHeading>
                {t('Customers')}
            </PageHeading>
            <Button
                mx={20}
                mb={20}
                onClick={() => setAddDataDrawerState(true)}
            >
                {t('Add')}
            </Button>

            <CustomDataGrid
                data={data}
                total={total}
                loading={loading}
                noData={noData}
                load={load}
                search={search}
                setEditData={setEditData}
                isEmployee={false}
            />

            <AddData
                opened={addDataDrawerState}
                refreshData={refreshData}
                onClose={() => setAddDataDrawerState(false)}
            />

            <EditData
                data={editData}
                opened={editData !== null}
                refreshData={refreshData}
                onClose={() => setEditData(null)}
            />
        </>
    );
}