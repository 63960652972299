import { Box, Button } from '@mantine/core';
import DataGridComponent from '../../../../components/DataGridComponent';
import NoDataFound from '../../../../components/NoDataFound';
import SkeletonRows from '../../../../components/SkeletonRows';
import { useTranslation } from 'react-i18next';
import { getCategories } from 'api/utility';
import { useData } from '../../../../hooks/useUsersData';
import PageHeading from 'components/PageHeading';
import AddData from "./_AddDrawer";
import EditData from "./_EditDrawer";
import { useHasPermission } from 'hooks';
import NoPermisionPage from 'components/NoPermisionPage';
export default function Category() {
    const { t } = useTranslation();
    const permissionGranted = useHasPermission(["invoices.read", "properties.read"]);

    const {
        loading,
        data,
        noData,
        total,
        editData,
        setEditData,
        addDataDrawerState,
        setAddDataDrawerState,
        load,
        search,
        refreshData,
    } = useData<any>({
        fetchData: (page, limit, search) => getCategories(page, limit, search).then(res => ({
            data: res.data.categories,
            total: res.data.total,
        })),
        errorMessage: t('Error while fetching categories data') as string,
    });

    const columns = [
        {
            accessorKey: 'category',
            header: t('الفئات'),
            filterFn: 'stringFilterFn',
            size: 300,
        },
        {
            accessorKey: 'description',
            header: t('Description'),
            filterFn: 'stringFilterFn',
        },
    ];
    if (!permissionGranted) {
        return <NoPermisionPage />;
    }
    return (
        <>
            <PageHeading>
                {t('الفئات')}
            </PageHeading>
            <Button
                mx={20}
                mb={20}
                onClick={() => setAddDataDrawerState(true)}
            >
                {t('Add Item')}
            </Button>
            <Box
                sx={{
                    padding: '0 20px',
                }}
            >
                {
                    data ? (
                        <DataGridComponent
                            data={data}
                            total={total}
                            loading={loading}
                            onPageChange={load}
                            onSearch={search}
                            onRowClick={(row) => setEditData(row)}
                            columns={columns}
                        />
                    ) : noData ? (
                        <NoDataFound />
                    ) : (
                        <SkeletonRows count={10} height={20} />
                    )
                }
            </Box>
            <AddData
                opened={addDataDrawerState}
                refreshData={refreshData}
                onClose={() => setAddDataDrawerState(false)}
            />

            <EditData
                data={editData}
                opened={editData !== null}
                refreshData={refreshData}
                onClose={() => setEditData(null)}
            />
        </>
    );
};

