import * as React from "react";
import * as ReactDOM from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider as StoreProvider } from "react-redux";

import store from "./redux/store";
import './i18n';
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyB0M7rlnnj1owEkpXJ1NGVZ4xiEgU2Azqg",
    authDomain: "alemtilak-58e4e.firebaseapp.com",
    projectId: "alemtilak-58e4e",
    storageBucket: "alemtilak-58e4e.firebasestorage.app",
    messagingSenderId: "928559686167",
    appId: "1:928559686167:web:e0215f153800165b96b252",
    measurementId: "G-TKZPWC279N"
};


const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
            // console.log("Service Worker registered with scope:", registration.scope);
        })
        .catch((error) => {
            // console.error("Service Worker registration failed:", error);
        });
}

ReactDOM.render(
    (<React.StrictMode>
        <BrowserRouter>
            <StoreProvider store={store}>
                <App />
            </StoreProvider>
        </BrowserRouter>
    </React.StrictMode>),
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
